<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Schedule Online Course
        <feather-icon icon="Edit2Icon" />
      </h4>

    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Select Category"

              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(addCourseForm.category)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Select Course"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules="required"
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.course"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="coursesList"
                  :reduce="(val) => val.id"
                  @input="getCourseDate(addCourseForm.course)"
                >
                  <template
                    v-if="!getCourse"
                    #no-options="{ search, searching, loading }"
                  >
                    Select Category First ...
                  </template>
                  <template
                    v-else
                    #no-options="{ search, searching, loading }"
                  >
                    Loading ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="12"
            xl="12"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules="required"
              >

                <flat-pickr
                  v-model="addCourseForm.date"
                  class="form-control "
                  style="display: none !important;"
                  :config="{ mode: 'multiple',inline: true,showMonths: 3}"
                />
              </validation-provider>

            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,

  BForm,
  BRow,
  BCol,
  BFormGroup,

  BButton,

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'

export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('')
    const coursesList = ref([])
    const getCourse = ref(false)
    const addCourseForm = reactive({

      course: '',
      category: '',
      date: [],

    })
    const categoriesList = ref([])

    const getCoursesByCategory = id => {
      addCourseForm.course = ''
      getCourse.value = true
      store
        .dispatch('categories/getCoursesByCategory', {

          online: 1,
          ' category_id': id,

        })
        .then(response => {
          coursesList.value = response.data.data
        })
    }
    store.dispatch('categories/AllCategory')
      .then(response => {
        categoriesList.value = response.data.data
      })

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('course_id', addCourseForm.course)
          formData.append('category_id', addCourseForm.category)
          formData.append('online', 1)
          formData.append('date', addCourseForm.date)

          store.dispatch('schedule/addCourseOnline', formData)
            .then(response => {
              addCourseForm.course = ''
              addCourseForm.category = ''
              addCourseForm.date = ''
              Vue.swal({
                title: 'Schedule Course Added ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    const getCourseDate = id => {
      // addCourseForm.date=[]
      //   console.log('addCourseForm.date',addCourseForm.date)
      const data = []
      store
        .dispatch('schedule/getCourseSchedule', {

          'filter[course_id]': id,
          'filter[online]': 1,

        })
        .then(response => {
          response.data.forEach(el => {
            data.push(el.date)
          })
          addCourseForm.date = data
        })
    }

    return {
      getCourseDate,
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
      categoriesList,

      getCoursesByCategory,
      save,
      getCourse,
      required,
      email,
    }
  },

  components: {
    BCard,

    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BForm,

    BRow,
    BCol,
    BButton,
    BFormGroup,
    vSelect,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";

</style>
